import * as Yup from 'yup';
import { phoneNumberValidationTest } from '../helpers/validationHelpers';
import { ERROR_REQUIRED_TXT } from '../constants/textConstants';

const validationSchema = Yup.object().shape({
  customUserInfo: Yup.boolean(),
  userFirstName: Yup.string().when('userType', {
    is: (val: string) => !!val && val !== 'other',
    then: Yup.string().when('userNewOrExisting', {
      is: 'new',
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),
    otherwise: Yup.string().required(ERROR_REQUIRED_TXT),
  }),

  userLastName: Yup.string().when('userType', {
    is: (val: string) => val !== 'other',
    then: Yup.string().when('userNewOrExisting', {
      is: 'new',
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),
    otherwise: Yup.string().required(ERROR_REQUIRED_TXT),
  }),

  userCompany: Yup.string().when('userType', {
    is: (val: string) => val !== 'other' && val !== 'referringDoctor',
    then: Yup.string().when('userNewOrExisting', {
      is: 'new',
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),
    otherwise: Yup.string(),
  }),
  userPhoneType: Yup.string(),

  userPhoneExtension: Yup.string(),
  userAdjuster: Yup.string().when('userType', {
    is: (val: string) => val === 'adjuster',
    then: Yup.string().when('userNewOrExisting', {
      is: 'existing',
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),
  }),
  userReferringPhysician: Yup.string().when('userType', {
    is: (val: string) => val === 'referringDoctor',
    then: Yup.string().when('userNewOrExisting', {
      is: 'existing',
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),
  }),
  userCaseManager: Yup.string().when('userType', {
    is: (val: string) => val === 'caseManager',
    then: Yup.string().when('userNewOrExisting', {
      is: 'existing',
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),
  }),
  userRoleInCase: Yup.string().when('userType', {
    is: (val: string) => val === 'other',
    then: Yup.string().required(ERROR_REQUIRED_TXT),
    otherwise: Yup.string(),
  }),
  userNewOrExisting: Yup.string().required(''),

  userEmail: Yup.string().when('userNewOrExisting', {
    is: 'new',
    then: Yup.string().when('userEmailPreferred', {
      is: true,
      then: Yup.string().email('Invalid email').required('Required when preferred'),
      otherwise: Yup.string().when(['userPhonePreferred', 'userEmail', 'userPhoneNumber'], {
        is: (userPhonePreferred, userEmail, userPhoneNumber) => !userPhonePreferred && !userEmail && !userPhoneNumber,
        then: Yup.string().required('Phone or email required'),
        otherwise: Yup.string().email('Invalid email')
      }),
    }),
  }),
  userPhoneNumber: Yup.string().when('userNewOrExisting', {
    is: 'new',
    then: Yup.string().when('userPhonePreferred', {
      is: true,
      then: Yup.string().test('len', 'Invalid phone', phoneNumberValidationTest).required('Required when preferred'),
      otherwise: Yup.string().when(['userEmailPreferred', 'userEmail', 'userPhoneNumber'], {
        is: (userEmailPreferred, userEmail, userPhoneNumber) => !userEmailPreferred && !userEmail && !userPhoneNumber,
        then: Yup.string().required('Phone or email required'),
        otherwise: Yup.string().test('len', 'Invalid phone', phoneNumberValidationTest)
      }),
    }),
  }),
  userPhonePreferred: Yup.boolean(),
  userEmailPreferred: Yup.boolean(),
});

export default validationSchema;
