import { Box } from '@mui/material';
// import { ReactComponent as BardLogo } from '../../images/icons/Bardavon_Prevention-Logo_Color_CMYK.svg';
import bardLogo from '../../images/icons/Bardavon-Website-Logo-1.png';
import React from 'react';
import styleVars from '../../styleVars';

const AppIcon = () => {
  return (
    <Box
      sx={{
        backgroundColor: styleVars.colorBodyBackground,
        width: '280px',
        height: '80px',
        marginLeft: '0',
        marginTop: '-1rem',
      }}
    >
      <img
        src={bardLogo}
        alt={'Bardavon Logo'}
        style={{ height: '71px', width: '322px' }}
      ></img>
      {/* <BardLogo /> */}
    </Box>
  );
};

export default AppIcon;
