import * as Yup from 'yup';
import differenceInMonths from 'date-fns/differenceInMonths';
import moment from 'moment';
import { phoneNumberValidationTest } from '../helpers/validationHelpers';
import { ERROR_REQUIRED_TXT } from '../constants/textConstants';

const validationSchema = Yup.object()
  .shape({
    patientFirstName: Yup.string()
      .max(50, 'First name cannot exceed 50 characters')
      .required(ERROR_REQUIRED_TXT),
    patientLastName: Yup.string()
      .max(50, 'Last name cannot exceed 50 characters')
      .required(ERROR_REQUIRED_TXT),
    patientPhoneNumber: Yup.string()
      .required(ERROR_REQUIRED_TXT)
      .test('len', 'Invalid phone number', phoneNumberValidationTest),
    patientBirthday: Yup.string()
      .nullable()
      .required(ERROR_REQUIRED_TXT)
      .test(
        'patientBirthday',
        'Should be greater than 13 years old',
        function (value) {
          return (
            differenceInMonths(
              new Date(),
              new Date(moment(value).format('MMM DD, YYYY HH:MM'))
            ) >= 156
          );
        }
      ),
    patientSex: Yup.string(),
    patientPrimaryLanguage: Yup.string(),
    interpreterAuthorized: Yup.boolean(),
    transportationAuthorized: Yup.boolean(),
    claimNumber: Yup.string()
      .required(ERROR_REQUIRED_TXT)
      .matches(/^[a-zA-Z0-9-]+$/, 'Claim number must only include letters, numbers, and hyphens'),
    stateOfJury: Yup.string().required(ERROR_REQUIRED_TXT),
    caseType: Yup.string(),
    specialty: Yup.string(),

    checkBoxForPhysicianInfo: Yup.boolean(),
    checkBoxPhysicianPhonePreferred: Yup.boolean(),
    checkBoxEmailPreferred: Yup.boolean(),

    treatingPhysician: Yup.string()
      .max(100, 'Treating physician name cannot exceed 100 characters')
      .when('checkBoxForPhysicianInfo', {
        is: false,
        then: Yup.string().required(ERROR_REQUIRED_TXT),
      }),

    dateOfInjury: Yup.string().nullable().required(ERROR_REQUIRED_TXT),
    physicianFirstName: Yup.string()
      .max(50, 'First name cannot exceed 50 characters')
      .when('checkBoxForPhysicianInfo', {
        is: true,
        then: Yup.string().required(ERROR_REQUIRED_TXT),
      }),
    physicianLastName: Yup.string()
      .max(50, 'Last name cannot exceed 50 characters')
      .when('checkBoxForPhysicianInfo', {
        is: true,
        then: Yup.string().required(ERROR_REQUIRED_TXT),
      }),
    physicianEmail: Yup.string().when('checkBoxForPhysicianInfo', {
      is: true,
      then: Yup.string().when('checkBoxEmailPreferred', {
        is: true,
        then: Yup.string().email('Invalid email').required('Required when preferred'),
        otherwise: Yup.string().when(['checkBoxPhysicianPhonePreferred', 'physicianEmail', 'physicianPhoneNumber'], {
          is: (checkBoxPhysicianPhonePreferred, physicianEmail, physicianPhoneNumber) => !checkBoxPhysicianPhonePreferred && !physicianEmail && !physicianPhoneNumber,
          then: Yup.string().required('Phone or email required'),
          otherwise: Yup.string().email('Invalid email')
        }),
      }),
    }),
    physicianPhoneNumber: Yup.string().when('checkBoxForPhysicianInfo', {
      is: true,
      then: Yup.string().when('checkBoxPhysicianPhonePreferred', {
        is: true,
        then: Yup.string().test('len', 'Invalid phone', phoneNumberValidationTest).required('Required when preferred'),
        otherwise: Yup.string().when(['checkBoxEmailPreferred', 'physicianEmail', 'physicianPhoneNumber'], {
          is: (checkBoxEmailPreferred, physicianEmail, physicianPhoneNumber) => !checkBoxEmailPreferred && !physicianEmail && !physicianPhoneNumber,
          then: Yup.string().required('Phone or email required'),
          otherwise: Yup.string().test('len', 'Invalid phone', phoneNumberValidationTest)
        }),
      }),
    }),
    physicianPhoneType: Yup.string(),
    physicianPhoneExtension: Yup.number().max(
      6,
      'extension cannot exceed 6 characters'
    ),
    medicalDiagnosis: Yup.array()
      .max(15, 'Medical diagnoses cannot exceed 15 selections')
      .nullable(),
    bodyPart: Yup.array()
      .max(10, 'Body parts cannot exceed 10 selections')
      .nullable(),
    surgical: Yup.string(),

    programId: Yup.string().when('hasProgram', { is: true, then: Yup.string().required('Required') }),
    programRisk: Yup.string().when('hasProgram', { is: true, then: Yup.string().required('Required') }),

    checkBoxForEmployer: Yup.boolean(),
    employerSelected: Yup.string().nullable(),
    jobTitle: Yup.string().max(100, "Must be 100 characters or less"),
    employerCompany: Yup.string()
      .max(100, 'Company name cannot exceed 100 characters')
      .when('checkBoxForEmployer', {
        is: true,
        then: Yup.string().required(ERROR_REQUIRED_TXT),
      }),
    employerContactName: Yup.string().max(100, "Must be 100 characters or less"),
    employerAddress: Yup.string().max(255, "Must be 100 characters or less"),
    employerCity: Yup.string().max(100, "Must be 100 characters or less"),
    employerState: Yup.string(),
    employerZipCode: Yup.string().matches(/^\d{5}(-\d{4})?$/, 'Must be a valid postal code (5 or 9 digits)'),
    checkBoxEmployerEmailPreferred: Yup.boolean(),

    employerEmail: Yup.string().when('checkBoxForEmployer', {
      is: true,
      then: Yup.string().when('checkBoxEmployerEmailPreferred', {
        is: true,
        then: Yup.string().email('Invalid email').required('Required when preferred'),
        otherwise: Yup.string().when(['checkBoxEmployerPhonePreferred', 'employerEmail', 'employerPhoneNumber'], {
          is: (checkBoxEmployerPhonePreferred, employerEmail, employerPhoneNumber) => !checkBoxEmployerPhonePreferred && !employerEmail && !employerPhoneNumber,
          then: Yup.string().required('Phone or email required'),
          otherwise: Yup.string().email('Invalid email')
        }),
      }),
    }),
    employerPhoneNumber: Yup.string().when('checkBoxForEmployer', {
      is: true,
      then: Yup.string().when('checkBoxEmployerPhonePreferred', {
        is: true,
        then: Yup.string().test('len', 'Invalid phone', phoneNumberValidationTest).required('Required when preferred'),
        otherwise: Yup.string().when(['checkBoxEmployerEmailPreferred', 'employerEmail', 'employerPhoneNumber'], {
          is: (checkBoxEmployerEmailPreferred, employerEmail, employerPhoneNumber) => !checkBoxEmployerEmailPreferred && !employerEmail && !employerPhoneNumber,
          then: Yup.string().required('Phone or email required'),
          otherwise: Yup.string().test('len', 'Invalid phone', phoneNumberValidationTest)
        }),
      }),
    }),
    checkBoxEmployerPhonePreferred: Yup.boolean(),
    employerPhoneType: Yup.string(),
    employerPhoneExtension: Yup.number().max(
      6,
      'extension cannot exceed 6 characters'
    ),
  })
  .defined();

export default validationSchema;
